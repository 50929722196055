@charset "UTF-8";
@font-face {
  font-family: "AvenirNextLTPro";
  src: url("/fonts/AvenirNextLTPro-Medium.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Thin.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Thin.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Thin.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Thin.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Thin.svg#gustan_thin") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Thin-Italic.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Thin-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Thin-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Thin-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Thin-Italic.svg#gustan_thin_italic") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Light.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Light.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Light.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Light.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Light.svg#gustan_light") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Light-Italic.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Light-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Light-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Light-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Light-Italic.svg#gustan_light_italic") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Book.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Book.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Book.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Book.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Book.svg#gustan_book") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Book-Italic.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Book-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Book-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Book-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Book-Italic.svg#gustan_book_italic") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Medium.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Medium.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Medium.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Medium.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Medium.svg#gustan_medium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Medium-Italic.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Medium-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Medium-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Medium-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Medium-Italic.svg#gustan_medium_italic") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Bold.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Bold.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Bold.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Bold.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Bold.svg#gustan_bold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Gustan";
  src: url("https://www.dolby.com/fonts/Gustan-Bold-Italic.eot");
  src: url("https://www.dolby.com/fonts/Gustan-Bold-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Bold-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Bold-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Bold-Italic.svg#gustan_bold_italic") format("svg");
  font-weight: 700;
  font-style: italic;
}
form .notice {
  font-size: 16px;
  line-height: 20px;
}

fieldset {
  margin: 0;
  padding: 0;
  color: #293033;
  border-style: none;
  margin-bottom: 60px;
  font-size: 12px;
  line-height: 15px;
}
fieldset legend {
  padding: 6px 20px;
  color: #293033;
  line-height: 28px;
  font-size: 20px;
  font-weight: 300;
  background-color: #F7F7F7;
  box-sizing: border-box;
  width: 100%;
  max-width: 315px;
}
fieldset div.fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 79px;
  display: -ms-grid;
  -ms-grid-columns: 1fr 79px 1fr;
}
fieldset div.fields > div {
  min-height: 82px;
  line-height: 16px;
}
fieldset div.fields > div > p {
  margin: 0;
  padding: 0;
}
fieldset div.fields > div > p > span {
  display: block;
}
fieldset div.fields > div > p > span br {
  display: none;
}

label {
  color: #6E828B;
  font-size: 12px;
  line-height: 16px;
}

input, select, textarea {
  font-weight: 300;
  font-size: 20px;
  line-height: 1.4em;
  letter-spacing: 0.4px;
  border-style: none;
  color: #293033;
  margin-bottom: 6px;
  border-bottom: 1px solid #D8D8D8;
  padding: 12px 1px 3px;
  box-sizing: content-box;
  background-color: transparent;
  box-sizing: border-box;
  width: 100%;
  max-width: 335px;
  height: 44px;
}
input.filled, select.filled, textarea.filled {
  border-bottom: 2px solid #C8D4DA;
  padding-bottom: 2px;
}
input:focus, select:focus, textarea:focus {
  max-width: 303px;
  background-color: #E7EDF1;
  border-bottom-style: none;
  padding: 10px 10px 6px;
  outline: 1px solid #00B1F4;
  outline-offset: -1px;
}

textarea {
  max-width: unset;
  box-sizing: border-box;
  height: 7em;
  border: 1px solid #D8D8D8;
}
textarea:focus {
  max-width: unset;
}

input[type=checkbox] {
  background: #00B1F4 !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  color: white;
  margin: 0 10px 0 3px;
  padding: 0;
}
input[type=checkbox]:checked {
  background: #00B1F4;
}

button, input[type=submit], input[type=button] {
  border: 2px solid #E7EDF1;
  border-radius: 1px;
  padding: 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.25em;
  letter-spacing: 0.4px;
  color: #F8FAFB;
  background-color: #3E44FE;
  height: 44px;
  width: 335px;
  max-width: 100%;
}
button:disabled, input[type=submit]:disabled, input[type=button]:disabled {
  border-color: #1EB2F1;
  background-color: #FFF;
  color: #6E828B;
}

@media (max-width: 748px) {
  fieldset div.fields {
    display: block;
  }
}
#ieWarning {
  padding: 0 79px;
  color: red;
}
#ieWarning a:link {
  text-decoration: underline;
}

* {
  font-family: "AvenirNextLTPro", "Gustan", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
*:lang(ja-JP) {
  font-family: "Hiragino Kaku Gothic Pro", "Meiryo", "MS PGothic", "Osaka", Arial, sans-serif;
}
*:lang(ko-KR) {
  font-family: "맑은고딕", Malgun Gothic, "돋움", Dotum, "굴림", Gulim, Arial, Helvetica, sans-serif;
}
*:lang(zh-CN) {
  font-family: "Microsoft YaHei", 微软雅黑, Verdana, Arial, Helvetica, sans-serif;
}

body {
  background-color: white;
  margin: 0;
  color: #3D484D;
  line-height: 1.5em;
}
body a:link, body a:visited {
  text-decoration: none;
  color: inherit;
}
body > header {
  padding: 0 79px;
  background-color: #3E44FE;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas: "title language";
  height: 75px;
  display: -ms-grid;
  -ms-grid-columns: 1fr auto;
  -ms-grid-rows: 75px;
}
body > header h1 {
  grid-area: title;
  justify-self: start;
  align-self: center;
  font-weight: normal;
  margin: 0;
  padding: 0;
  font-size: 20px;
  line-height: 1.25;
  color: white;
  font-weight: normal;
  align-content: center;
  height: 46px;
  line-height: 46px;
  padding: 1px 0;
  -ms-grid-column: 1;
  -ms-grid-row: 1;
  -ms-grid-row-align: center;
  -ms-grid-column-align: start;
}
body > header h1 img {
  padding-right: 24px;
  float: left;
  padding-top: 7px;
}
body > header form {
  text-align: right;
  grid-area: language;
  justify-self: end;
  align-self: center;
  -ms-grid-column: 2;
  -ms-grid-row: 1;
  -ms-grid-row-align: center;
  -ms-grid-column-align: end;
}
body > header form label span {
  display: none;
}
body > header form label select {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: normal;
  height: 30px;
  width: auto;
  border-style: none;
  color: white;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
body > header form label select.filled {
  border-style: none;
  padding: 0;
}
body > header form label select:focus {
  width: auto;
  background-color: transparent;
  border-bottom-style: none;
  padding: 0;
  outline-style: none;
}
body > header form label select option {
  background-color: #293033;
}
body main {
  display: block;
  padding: 0 79px 100px;
  max-width: 749px;
  margin: 0;
}
body main a:link, body main a:visited {
  border-bottom: solid 1px;
}
body main .error {
  color: #BF1512;
}
body main h2 {
  font-weight: 200;
  font-size: 44px;
  margin: 39px 0 40px;
}
body > footer {
  font-size: 12px;
  color: #6E828B;
}
body > footer #links {
  background-color: #E7EDF1;
}
body > footer #links ul {
  line-height: 55px;
  margin: 0;
  display: table;
  margin-left: 79px;
  padding: 0;
}
body > footer #links ul li {
  display: block;
  margin: 0 2em;
  display: table-cell;
  text-align: center;
  padding: 0 1em;
}
body > footer #links ul li:first-child {
  padding-left: 0;
}
body > footer #copyright {
  font-family: Tahoma, Arial, Helvetica, sans-serif;
  padding: 0 79px;
}

@media (max-width: 748px) {
  body > header {
    padding: 0 20px;
  }
  body > header h1 {
    font-size: 16px;
  }
  body > header h1 img {
    width: 39px;
    padding-right: 24px;
    padding-top: 8px;
  }
  body > header form label select {
    font-size: 14px;
    line-height: 18px;
  }
  body > header form label select option {
    color: #3D484D;
    background-color: white;
  }
  body main {
    padding: 0 20px 50px;
  }
  body main h2 {
    font-size: 32px;
    margin: 68px 0 40px;
  }
  body > footer #links ul {
    margin: 0 20px;
  }
  body > footer #copyright {
    padding: 0 20px;
  }
}