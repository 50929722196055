@font-face {
    font-family: 'AvenirNextLTPro';
    src: url("/fonts/AvenirNextLTPro-Medium.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Thin.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Thin.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Thin.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Thin.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Thin.svg#gustan_thin") format("svg");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Thin-Italic.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Thin-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Thin-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Thin-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Thin-Italic.svg#gustan_thin_italic") format("svg");
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Light.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Light.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Light.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Light.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Light.svg#gustan_light") format("svg");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Light-Italic.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Light-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Light-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Light-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Light-Italic.svg#gustan_light_italic") format("svg");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Book.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Book.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Book.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Book.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Book.svg#gustan_book") format("svg");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Book-Italic.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Book-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Book-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Book-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Book-Italic.svg#gustan_book_italic") format("svg");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Medium.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Medium.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Medium.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Medium.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Medium.svg#gustan_medium") format("svg");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Medium-Italic.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Medium-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Medium-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Medium-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Medium-Italic.svg#gustan_medium_italic") format("svg");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Bold.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Bold.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Bold.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Bold.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Bold.svg#gustan_bold") format("svg");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Gustan';
    src: url("https://www.dolby.com/fonts/Gustan-Bold-Italic.eot");
    src: url("https://www.dolby.com/fonts/Gustan-Bold-Italic.eot?#iefix") format("embedded-opentype"), url("https://www.dolby.com/fonts/Gustan-Bold-Italic.woff") format("woff"), url("https://www.dolby.com/fonts/Gustan-Bold-Italic.ttf") format("truetype"), url("https://www.dolby.com/fonts/Gustan-Bold-Italic.svg#gustan_bold_italic") format("svg");
    font-weight: 700;
    font-style: italic;
}

@import '_variables.scss';
@import '_foms.scss';

#ieWarning {
    padding: 0 79px;
    color: red;

    a:link {
        text-decoration: underline;
    }
}

* {
    font-family: $font-stack;

    &:lang(ja-JP) {
        font-family: "Hiragino Kaku Gothic Pro", "Meiryo", "MS PGothic", "Osaka", Arial, sans-serif;
    }

    &:lang(ko-KR) {
        font-family: '맑은고딕', Malgun Gothic, '돋움', Dotum, '굴림', Gulim, Arial, Helvetica, sans-serif;
    }

    &:lang(zh-CN) {
        font-family: "Microsoft YaHei", 微软雅黑, Verdana, Arial, Helvetica, sans-serif;
    }
}

body {
    background-color: white;
    margin: 0;
    color: #3D484D;
    line-height: 1.5em;

    a:link, a:visited {
        text-decoration: none;
        color: inherit;
    }

    > header {
        padding: 0 79px;
        background-color: #3E44FE;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-areas: "title language";
        height: 75px;
        display: -ms-grid;
        -ms-grid-columns: 1fr auto;
        -ms-grid-rows: 75px;

        h1 {
            grid-area: title;
            justify-self: start;
            align-self: center;
            font-weight: normal;
            margin: 0;
            padding: 0;
            font-size: 20px;
            line-height: 1.25;
            color: white;
            font-weight: normal;
            align-content: center;
            height: 46px;
            line-height: 46px;
            padding: 1px 0;
            -ms-grid-column: 1;
            -ms-grid-row: 1;
            -ms-grid-row-align: center;
            -ms-grid-column-align: start;

            img {
                padding-right: 24px;
                float: left;
                padding-top:7px;
            }
        }

        form {
            text-align: right;
            grid-area: language;
            justify-self: end;
            align-self: center;
            -ms-grid-column: 2;
            -ms-grid-row: 1;
            -ms-grid-row-align: center;
            -ms-grid-column-align: end;

            label {
                span {
                    display: none;
                }

                select {
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: normal;
                    height: 30px;
                    width: auto;
                    border-style: none;
                    color: white;
                    padding: 0;
                    margin: 0;
                    background-color: transparent;

                    &.filled {
                        border-style: none;
                        padding: 0;
                    }

                    &:focus {
                        width: auto;
                        background-color: transparent;
                        border-bottom-style: none;
                        padding: 0;
                        outline-style: none;
                    }

                    option {
                        background-color: #293033;
                    }
                }
            }
        }
    }

    main {
        display: block;
        padding: 0 79px 100px;
        max-width: 749px;
        margin: 0;


        a:link, a:visited {
            border-bottom: solid 1px;
        }

        .error {
            color: #BF1512;
        }

        h2 {
            font-weight: 200;
            font-size: 44px;
            margin: 39px 0 40px;
        }
    }

    > footer {
        font-size: 12px;
        color: #6E828B;

        #links {
            background-color: #E7EDF1;

            ul {
                line-height: 55px;
                margin: 0;
                display: table;
                margin-left: 79px;
                padding: 0;


                li {
                    display: block;
                    margin: 0 2em;
                    display: table-cell;
                    text-align: center;
                    padding: 0 1em
                }

                li:first-child {
                    padding-left: 0;
                }
            }
        }

        #copyright {
            font-family: Tahoma, Arial, Helvetica, sans-serif;
            padding: 0 79px;
        }
    }
}

@media (max-width:748px) {
    body {
        > header {
            padding: 0 20px;

            h1 {
                font-size: 16px;

                img {
                    width: 39px;
                    padding-right: 24px;
                    padding-top: 8px;
                }
            }

            form {

                label {

                    select {
                        font-size: 14px;
                        line-height: 18px;

                        option {
                            color: #3D484D;
                            background-color: white;
                        }
                    }
                }
            }
        }

        main {
            padding: 0 20px 50px;

            h2 {
                font-size: 32px;
                margin: 68px 0 40px;
            }
        }

        > footer {
            #links {
                ul {
                    margin: 0 20px;
                }
            }

            #copyright {
                padding: 0 20px;
            }
        }
    }
}
