﻿form {
    .notice {
        font-size: 16px;
        line-height: 20px;
    }
}

fieldset {
    margin: 0;
    padding: 0;
    color: #293033;
    border-style: none;
    margin-bottom: 60px;
    font-size: 12px;
    line-height: 15px;

    legend {
        padding: 6px 20px;
        color: #293033;
        line-height: 28px;
        font-size: 20px;
        font-weight: 300;
        background-color: #F7F7F7;
        box-sizing: border-box;
        width: 100%;
        max-width: 315px;
    }

    div.fields {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 79px;

        display: -ms-grid;
        -ms-grid-columns: 1fr 79px 1fr;

        > div {
            min-height: 82px;
            line-height: 16px;

            > p {
                margin: 0;
                padding: 0;

                > span {
                    display: block;

                    br {
                        display: none;
                    }
                }
            }
        }
    }
}



label {
    color: #6E828B;
    font-size: 12px;
    line-height: 16px;
}

input, select, textarea {
    font-weight: 300;
    font-size: 20px;
    line-height: 1.4em;
    letter-spacing: 0.4px;
    border-style: none;
    color: #293033;
    margin-bottom: 6px;
    border-bottom: 1px solid #D8D8D8;
    padding: 12px 1px 3px;
    box-sizing: content-box;
    background-color: transparent;
    
    box-sizing: border-box;
    width: 100%;
    max-width: 335px;
    height: 44px;

    &.filled {
        border-bottom: 2px solid #C8D4DA;
        padding-bottom: 2px;
    }

    &:focus {
        max-width: 303px;
        background-color: #E7EDF1;
        border-bottom-style: none;
        padding: 10px 10px 6px;
        outline: 1px solid #00B1F4;
        outline-offset: -1px;
    }
}

textarea {
    max-width: unset;
    box-sizing: border-box;
    height: 7em;
    border: 1px solid #D8D8D8;

    &:focus {
        max-width: unset;
    }
}

input[type=checkbox] {
    background: #00B1F4 !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    color: white;
    margin: 0 10px 0 3px;
    padding: 0;

    &:checked {
        background: #00B1F4;
    }
}

button, input[type=submit], input[type=button] {
    border: 2px solid #E7EDF1;
    border-radius: 1px;
    padding: 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.25em;
    letter-spacing: 0.4px;
    color: #F8FAFB;
    background-color: #3E44FE;
    height: 44px;
    width: 335px;
    max-width: 100%;

    &:disabled {
        border-color: #1EB2F1;
        background-color: #FFF;
        color: #6E828B;
    }
}

@media (max-width:748px) {
    fieldset {
        div.fields {
            display: block;
           
        }
    }
}
